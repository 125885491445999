import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CgEditorComponent } from './cg-editor.component'
import { QuillModule } from 'ngx-quill'
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        QuillModule.forRoot({
        })
    ],
    declarations: [CgEditorComponent],
    exports: [CgEditorComponent]
})
export class CgEditorModule { }

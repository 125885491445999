import { Component, OnInit, AfterViewInit, ViewChild, Renderer2, ElementRef, Output, Input, forwardRef } from '@angular/core'
import { FormGroup, FormGroupDirective, FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import Quill from 'quill'
import { CustomAttribute } from './blots/custom-attribute'

@Component({
    selector: 'app-cg-editor',
    templateUrl: './cg-editor.component.html',
    styleUrls: ['./cg-editor.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CgEditorComponent),  // replace name as appropriate
            multi: true
        }
    ]
})
export class CgEditorComponent implements OnInit, AfterViewInit, ControlValueAccessor {
    form: FormGroup
    editor: any
    disabled = false
    value: any = ''
    selectedAttributeId: string = 'none'
    attributes = [
        {
            id: 'none',
            title: 'Select Attribute'
        },
        {
            id: 'applicantName',
            title: 'Applicant Name'
        },
        {
            id: 'respondentName',
            title: 'Respondent Name'
        },
        {
            id: 'applicantAddress',
            title: 'Applicant Address'
        },
        {
            id: 'respondentAddress',
            title: 'Respondent Address'
        },
        {
            id: 'onAFinalBasis',
            title: 'On a final basis'
        },
        {
            id: 'onATemporaryBasis',
            title: 'On a temporary basis'
        },
        {
            id: 'onATemporaryWithoutPrejudiceBasis',
            title: 'On a temporary without-prejudice basis'
        },
        {
            id: 'childName',
            title: 'Child Name'
        },
        {
            id: 'childDob',
            title: 'Child Date of Birth'
        },
        {
            id: 'annualIncome',
            title: 'Annual Income'
        }
    ]

    constructor(
        // private rootFormGroup: FormGroupDirective
        private r2: Renderer2
    ) {
    }

    onChange: any = () => { }
    onTouched: any = () => { }

    ngOnInit() {
        // this.form = this.rootFormGroup.control
        const backgroundClass = Quill.import('attributors/class/background')
        const colorClass = Quill.import('attributors/class/color')
        const sizeStyle = Quill.import('attributors/style/size')
        Quill.register(backgroundClass, true)
        Quill.register(colorClass, true)
        Quill.register(sizeStyle, true)
        Quill.register(CustomAttribute, true)

        this.editor = new Quill('#editor', {
            modules: {
                toolbar: {
                    container: '#toolbar'
                }
            }
        })
        this.editor.insertText(0, 'This is sample Text')
        this.editor.on('editor-change', (eventName: any, a: any) => {
            // this.editor.getContents()
            // this.form.controls[this.fControlName].setValue(document.querySelector('.ql-editor')?.innerHTML)
            this.value = document.querySelector('.ql-editor')?.innerHTML
            this.onChange(this.value)
        })
    }

    ngAfterViewInit(): void {
    }

    addAttribute(attribute: any) {
        if (attribute.id === 'none') {
            return
        }

        const range = this.editor.getSelection(true)
        if (range != null) {

            // const html = `<span class="custom-attribute" data-id="${attributeId}" contenteditable="false">${attribute.title}</span>`
            this.editor.insertEmbed(range.index, 'CustomAttribute', CustomAttribute.html(attribute))
            // this.editor.clipboard.dangerouslyPasteHTML(range.index, '<span class="test">Hello world!. This is a test paragraph</span>')

            setTimeout ( () => this.selectedAttributeId = 'none', 100)
        }
    }

    // ControlValueAccessor
    writeValue(value: any): void {
        this.value = value
    }

    registerOnChange(fn: any): void {
        this.onChange = fn
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled
    }
}

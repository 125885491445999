export const environment = {
    production: false,
}

export const apis = {
    baseUrl: 'https://elite-api.codegenio.com/api',
    //baseUrl: 'http://localhost:8000/api',
    googleApiKey: 'AIzaSyCq-_3Fqmw-4Y6tdKFToydCYdVaprzHTjA',
    googleCaptchaSiteKey: ''
}

export const socialLoginUrls = {
    google: `${apis.baseUrl}/public/login/google`,
    facebook: `${apis.baseUrl}/public/login/facebook`
}

export const appURL = 'https://quiz-app-api.codegenio.com '

import { ApiService } from './../../../services/api.service'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Params, Router } from '@angular/router'

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    filters: any = {
        title: ''
    }
    hasUser: boolean = false
    constructor(
        public router: Router,
        private route: ActivatedRoute,
        public api: ApiService
    ) {
        this.route.queryParams.subscribe((param: Params) => {
            if (param.country) {
                this.filters.country = param.country
            } else {
                this.filters.country = 'USA'
            }
        })
    }

    ngOnInit() {
        let user: any = localStorage.getItem('user') as string

        if (user) {
            this.hasUser = true
        }
    }
    openFile() {
        window.open("assets/images/terms-condition.pdf", '_blank');
    }

    redirectToSearch() {
        const filtersParam = {
            country: this.filters.country,
            title: this.filters.title
        }
        this.router.navigate(['/search'], { queryParams: filtersParam, replaceUrl: true })
    }

    scrollToElement($element: any) {
        const element = document.querySelector($element)
        console.log('element', element)
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            })
        }
    }
}

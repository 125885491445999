import { Injectable, Inject } from '@angular/core'

import { PLATFORM_ID } from '@angular/core'
import { isPlatformBrowser } from '@angular/common'

import 'prismjs'
import 'prismjs/plugins/toolbar/prism-toolbar'
import 'prismjs/plugins/unescaped-markup/prism-unescaped-markup'
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard'
import 'prismjs/plugins/line-numbers/prism-line-numbers'
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace'
import 'prismjs/components/prism-css'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-typescript'
import 'prismjs/components/prism-scss'
declare var Prism: any

@Injectable()
export class PrismService {

    constructor(@Inject(PLATFORM_ID) private platformId: object) { }

    highlightAll() {
        Prism.plugins.NormalizeWhitespace.setDefaults({
            'remove-trailing': true,
            'remove-indent': true,
            'left-trim': true,
            'right-trim': true
            /*'break-lines': 80,
            'indent': 2,
            'remove-initial-line-feed': false,
            'tabs-to-spaces': 4,
            'spaces-to-tabs': 4*/
        })

        if (isPlatformBrowser(this.platformId)) {
            Prism.highlightAll()
        }
    }
}

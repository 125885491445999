import { TranslateService } from '@ngx-translate/core'
import { Router } from '@angular/router'
import { ConstantsService } from './constants.service'
import { map } from 'rxjs/operators'
import { apis } from '../../environments/environment'
import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, BehaviorSubject, Subject } from 'rxjs'
import { User } from '../models/user'

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    baseUrl: string
    userLoggedInSource = new BehaviorSubject(false)
    scrollBottom: boolean
    scrollBottomChange = new Subject<boolean>()
    userImage = new Subject<string>()
    manAndWan: boolean = false
    euorpeanRemovals: boolean = false
    houseRemovals: boolean = false
    pakingServices: boolean = false
    storageServices: boolean = false
    servicesListEuropean: any = []
    listService: any = []
    servicesListMan: any = []
    servicesListHouse: any = []
    servicesListPacking: any = []
    servicesListStorage: any = []

    userLoggedInObs = this.userLoggedInSource.asObservable()
    user: User
    conatctNo: any
    description: any
    contactEnable: any
    private selectedServiceIdSubject = new BehaviorSubject<string | null>(null)
    private selectedOrderIdSubject = new BehaviorSubject<string | null>(null)

    setSelectedServiceId(serviceType: any) {
        this.selectedServiceIdSubject.next(serviceType)
    }

    get selectedServiceId$() {
        return this.selectedServiceIdSubject.asObservable()
    }

    setOrderId(id: any) {
        this.selectedOrderIdSubject.next(id)
    }

    get selectedOrderId$() {
        return this.selectedOrderIdSubject.asObservable()
    }

    constructor(
        public http: HttpClient,
        public cs: ConstantsService,
        private ts: TranslateService
    ) {
        this.baseUrl = apis.baseUrl + '/public'
        this.scrollBottom = false
        this.scrollBottomChange.subscribe((value) => {
            this.scrollBottom = value
        })
        if (localStorage.getItem('token')) {
            this.user = JSON.parse(localStorage.getItem('user') as '')
            this.userLoggedInSource.next(true)
        } else {
            this.userLoggedInSource.next(false)
        }
    }

    toggleScrollBottom(value: boolean): void {
        this.scrollBottomChange.next(value)
    }

    login(params: any): Observable<any> {
        const url = `${this.baseUrl}/login`

        return this.http.post<any>(url, params).pipe(
            map((resp) => {
                if (resp && resp.success && resp.data.token) {
                    localStorage.setItem('token', resp.data.token)
                    localStorage.setItem('user', JSON.stringify(resp.data))
                    this.user = resp.data
                    this.userLoggedInSource.next(true)
                }

                return resp
            })
        )
    }

    getServicesList() {
        const url = `${this.baseUrl}/menu-service/list`
        this.http.get<any>(url).subscribe((resp: any) => {
            if (resp.success === true) {
                resp.data.forEach((e: any, i: number) => {
                    if (i <= 4) {
                        this.listService.push(e)
                    }
                })
                resp.data.forEach((element: any) => {
                    if (element.type === 'Man_Van' && element.parentId == 0) {
                        this.servicesListMan = element.children || []
                    } else if (element.type === 'european_removals' && element.parentId == 0) {
                        this.servicesListEuropean = element.children || []
                    } else if (element.type === 'house_removals' && element.parentId == 0) {
                        this.servicesListHouse = element.children || []
                    } else if (element.type === 'packing_movers' && element.parentId == 0) {
                        this.servicesListPacking = element.children || []
                    } else if (element.type === 'storage_plan' && element.parentId == 0) {
                        this.servicesListStorage = element.children || []
                    }

                });
                this.servicesListMan.forEach((element: any) => {
                    if (element.name.length > 15) {
                        this.manAndWan = true
                    }
                })
                this.servicesListEuropean.forEach((element: any) => {
                    if (element.name.length > 15) {
                        this.euorpeanRemovals = true
                    }
                })
                this.servicesListHouse.forEach((element: any) => {
                    if (element.name.length > 15) {
                        this.houseRemovals = true
                    }
                })
                this.servicesListPacking.forEach((element: any) => {
                    if (element.name.length > 15) {
                        this.pakingServices = true
                    }
                })
                this.servicesListStorage.forEach((element: any) => {
                    if (element.name.length > 15) {
                        this.storageServices = true
                    }
                })
            }
        })
    }

    webSettingsDetail() {
        const url = `${this.baseUrl}/web-setting/detail`

        this.http.get<any>(url).subscribe((resp: any) => {
            if (resp.success === true) {
                this.conatctNo = this.formatPhoneNumber(resp.data.contact)
                this.description = resp.data.description
                this.contactEnable = resp.data.contactEnable
            }
        })
    }
    formatPhoneNumber(phoneNumber: any): any {
        if (phoneNumber.includes('-')) {
            phoneNumber = phoneNumber.split('-')
            let code = phoneNumber[0]
            let cleanNum = phoneNumber[1].toString().replace(/\D/g, '')
            const match = cleanNum.match(/^(\d{5})(\d{0,6})$/)
            if (match) {
                return `${code}-` + match[1] + '-' + match[2]
            }
            return `${code} - ${cleanNum}`
        }
        else {
            phoneNumber = phoneNumber.toString().replace(/\D/g, '')
            const last11Characters = phoneNumber.substr(-11)
            const code = phoneNumber.substring(0, (phoneNumber.length - last11Characters.length))
            const match = last11Characters.match(/^(\d{5})(\d{0,6})$/)
            if (match) {
                return `+${code}-` + match[1] + '-' + match[2]
            }
        }
    }

    googleLogin(): Observable<any> {
        const url = `${this.baseUrl}/login/${'google'}`

        return this.http.get<any>(url).pipe(
            map((resp) => {
                if (resp && resp.success && resp.data.token) {
                    localStorage.setItem('token', resp.data.token)
                    localStorage.setItem('user', JSON.stringify(resp.data))
                    this.user = resp.data
                    this.userLoggedInSource.next(true)
                }

                return resp
            })
        )
    }

    register(params: any): Observable<any> {
        const url = `${this.baseUrl}/register`

        return this.http.post<any>(url, params).pipe(
            map((resp) => {
                if (resp && resp.success && resp.data.token) {
                    localStorage.setItem('token', resp.data.token)
                    localStorage.setItem('user', JSON.stringify(resp.data))
                    this.user = resp.data
                    this.userLoggedInSource.next(true)
                }

                return resp
            })
        )
    }

    doUserRedirects(resp: any, router: Router) {
        switch (resp.data.userType) {
            case 'Admin': {
                router.navigate(['/admin/dashboard'])
                break
            }
            case 'Customer': {
                router.navigate(['/customer/orders-history'])
                break
            }
            case 'TradePartner': {
                router.navigate(['/trade-partner/orders-history'])
                break
            }
        }
    }

    logOut(): boolean {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        this.user.id = 0
        this.userLoggedInSource.next(false)

        return true
    }

    logOutSession(): Observable<any> {
        const url = `${this.baseUrl}/logout`

        return this.http.post<any>(url, {})
    }

    checkPermission(permission: string): boolean {
        // const index = this.user.UserPermissions.findIndex((item: any) => item.Permission.title === permission)

        // if (index > -1) {
        //     return true
        // }

        // return false
        return true
    }

    checkPermissions(permissions: Array<string>): boolean {
        // const checkUrserRole = this.user.userroles.findIndex(r => r.role.name === ConstantsService.ADMIN)

        // if (checkUrserRole > -1) {
        //     return true
        // }

        // if (permissions[0] == '') {
        //     return true
        // }

        // // const index = this.user.UserPermissions.findIndex((item: any) => permissions.indexOf(item.Permission.title) > -1)
        // if (index > -1) {
        //     return true
        // }

        // return false
        return true
    }

    checkRole() {
        return true
        // const checkUrserRole = this.user.userroles.findIndex(r => r.role.name === ConstantsService.ADMIN)

        // if (checkUrserRole > -1) {
        //     return true
        // }

        // return false
    }

    isAuthenticated(): boolean {
        if (localStorage.getItem('token')) {
            return true
        }

        return false
    }

    isAdmin(): boolean {
        if (this.isAuthenticated() && this.user.userType === ConstantsService.ADMIN) {
            return true
        }

        return false
    }
    isCustomer(): boolean {
        if (this.isAuthenticated() && this.user.userType === ConstantsService.Customer) {
            return true
        }

        return false
    }
    isTardePartner(): boolean {
        if (this.isAuthenticated() && this.user.userType === ConstantsService.TardePartner) {
            return true
        }

        return false
    }

    jsonToFormData(jsonObject: any, parentKey?: any, carryFormData?: FormData): FormData {
        const formData = carryFormData || new FormData()
        let index = 0

        // tslint:disable-next-line: forin
        for (const key in jsonObject) {
            if (jsonObject.hasOwnProperty(key)) {
                if (jsonObject[key] !== null && jsonObject[key] !== undefined) {
                    let propName = parentKey || key
                    if (parentKey && this.isObject(jsonObject)) {
                        propName = parentKey + '[' + key + ']'
                    }
                    if (parentKey && this.isArray(jsonObject)) {
                        propName = parentKey + '[' + index + ']'
                    }
                    if (jsonObject[key] instanceof File) {
                        formData.append(propName, jsonObject[key])
                    } else if (jsonObject[key] instanceof FileList) {
                        for (let j = 0; j < jsonObject[key].length; j++) {
                            formData.append(propName + '[' + j + ']', jsonObject[key].item(j))
                        }
                    } else if (this.isArray(jsonObject[key]) || this.isObject(jsonObject[key])) {
                        this.jsonToFormData(jsonObject[key], propName, formData)
                    } else if (typeof jsonObject[key] === 'boolean') {
                        formData.append(propName, +jsonObject[key] ? '1' : '0')
                    } else {
                        formData.append(propName, jsonObject[key])
                    }
                }
            }
            index++
        }

        return formData
    }

    isArray(val: any) {
        const toString = {}.toString

        return toString.call(val) === '[object Array]'
    }

    isObject(val: any) {
        return !this.isArray(val) && typeof val === 'object' && !!val
    }

    checkVerificationCode(data: any): Observable<any> {
        const url = `${this.baseUrl}/verify-email`

        return this.http.post<any>(url, data)
    }

    resendVerificationCode(data: any): Observable<any> {
        const url = `${this.baseUrl}/resend-code`

        return this.http.post<any>(url, data)
    }

    checkVerifyCode(data: any): Observable<any> {
        const url = `${this.baseUrl}/verify-code`

        return this.http.post<any>(url, data)
    }

    resetPass(data: any): Observable<any> {
        const url = `${this.baseUrl}/reset-password`

        return this.http.post<any>(url, data)
    }

    translate(key: string) {
        return this.ts.get(key)
    }

    getCurrentLang() {
        let lang = localStorage.getItem('lang')
            ? localStorage.getItem('lang')
            : this.ts.getBrowserLang()
        if (!lang) {
            lang = 'en'
        }
        return lang
    }

    changeLanguage(lang: string) {
        this.ts.currentLang = lang
        localStorage.setItem('lang', lang)
        this.ts.setDefaultLang(lang)
        this.ts.use(lang)
        location.reload()
    }

    userImageUrl(userId?: number) {
        userId = userId ? userId : -1

        return `${apis.baseUrl}/public/profile-image/${userId}`
    }
}

import { Event, Router, NavigationEnd } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { ThemeService } from './services/theme.service'
import { Observable } from 'rxjs'
import { ApiService } from './services/api.service'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    isLoading: boolean

    constructor(
        private route: Router,
        private themeService: ThemeService,
        public api: ApiService
    ) {
        this.route.events.subscribe((routerEvent: Event) => {
        })
    }

    ngOnInit() {
        this.themeService.setTheme('default')

        this.route.events.subscribe((routerEvent: Event) => {
            if (routerEvent instanceof NavigationEnd) {
                window.scrollTo(0, 0)
            }
        })
    }
}

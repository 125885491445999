<div id="toolbar">
    <!--
    <div class="tool font-size">
        <select class="ql-size">
            <option value="10px">Small</option>
            <option selected>Normal</option>
            <option value="18px">Large</option>
            <option value="32px">Huge</option>
        </select>
    </div>
    -->

    <div class="tool">
        <button class="ql-bold tool">
            <i class="fa fa-bold"></i>
        </button>
    </div>

    <div class="tool">
        <button class="ql-italic tool">
            <i class="fa fa-italic"></i>
        </button>
    </div>

    <div class="tool">
        <button class="ql-underline tool">
            <i class="fa fa-underline"></i>
        </button>
    </div>

    <div class="tool">
        <button class="ql-strike tool">
            <i class="fa fa-strikethrough"></i>
        </button>
    </div>

    <div class="tool">
        <div class="separator">|</div>
    </div>

    <div class="tool">
        <button class="ql-indent tool" value="-1">
            <i class="fa-solid fa-outdent"></i>
        </button>
    </div>

    <div class="tool">
        <button class="ql-indent tool" value="+1">
            <i class="fa-solid fa-indent"></i>
        </button>
    </div>

    <div class="tool">
        <div class="separator">|</div>
    </div>

    <div class="tool">
        <button class="ql-list tool" value="bullet">
            <i class="fa-solid fa-list"></i>
        </button>
    </div>

    <div class="tool">
        <button class="ql-list tool" value="ordered">
            <i class="fa-solid fa-list-ol"></i>
        </button>
    </div>

    <div class="tool">
        <div class="separator">|</div>
    </div>

    <div class="tool">
        <button class="ql-script tool" value="sub">
            <i class="fa-solid fa-subscript"></i>
        </button>
    </div>

    <div class="tool">
        <button class="ql-script tool" value="super">
            <i class="fa-solid fa-superscript"></i>
        </button>
    </div>

    <div class="tool">
        <div class="separator">|</div>
    </div>

    <div class="tool">
        <div class="cg-button-group">
            <button class="cg post white lg">Attributes</button>
            <label>
                <div class="icon white post">
                    <i class="fas fa-chevron-down"></i>
                </div>
                <input type="checkbox">
                <div class="button-menu warning">
                    <ul>
                        <li *ngFor="let attribute of attributes" [value]="attribute.id" (click)="addAttribute(attribute)">{{ attribute.title }}</li>
                    </ul>
                </div>
            </label>
        </div>
    </div>
</div>

<div id="editor" class="editor"></div>

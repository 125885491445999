<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="top-footer" style="position: relative;">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="heading01">
                                <!-- <h4>Heading 01</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                                <ul>
                                    <li>
                                        <img src="assets/images/check-circle.png" alt="" />
                                        <p>Professional, Reliable Removals</p>
                                    </li>
                                    <li>
                                        <img src="assets/images/check-circle.png" alt="" />
                                        <p>Over 20 Years Of Experience</p>
                                    </li>
                                    <li>
                                        <img src="assets/images/check-circle.png" alt="" />
                                        <p>We Aim For 100% Satisfaction</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="heading02">
                                <!-- <h4>Heading 01</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                                <ul>
                                    <li>
                                        <img src="assets/images/check-circle.png" alt="" />
                                        <p>Covering All Of London</p>
                                    </li>
                                    <li>
                                        <img src="assets/images/check-circle.png" alt="" />
                                        <p>Insured Services Available</p>
                                    </li>
                                    <li>
                                        <img src="assets/images/check-circle.png" alt="" />
                                        <p>Industry Quality Approved</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="heading03">
                                <!-- <h4>Heading 01</h4>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> -->
                                <ul>
                                    <li>
                                        <img src="assets/images/check-circle.png" alt="" />
                                        <p>National and International Removals</p>
                                    </li>
                                    <li>
                                        <img src="assets/images/check-circle.png" alt="" />
                                        <p>Long & Short Term Storage</p>
                                    </li>
                                    <li>
                                        <img src="assets/images/check-circle.png" alt="" />
                                        <p>Fast Customer Service</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-5">
                <div class="footer-logo">
                    <a href="#"><img src="assets/images/Elite-Moving-DM-03a-Final-01.png" alt="" /></a>
                </div>
                <div class="footer-social-icons">
                    <ul>
                        <li>
                            <a href="#"><img src="assets/images/linkedin.png" alt="" /></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/elitemovinguk/"><img src="assets/images/instagram.png"
                                    alt="" /></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/profile.php?id=100063708979563"><img
                                    src="assets/images/facebook.png" alt="" /></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/EliteMovingUK"><img src="assets/images/twitter.png"
                                    alt="" /></a>
                        </li>
                    </ul>
                </div>
                <div class="footer-location">
                    <img src="assets/images/map-pin.png" alt="" />
                    <p>Covering London, UK and Europe</p>
                </div>
                <div class="footer-phone">
                    <img src="assets/images/phone-call.png" alt="" />
                    <a href="tel:+44 74284 74474">+44 74284 74474</a>
                </div>
            </div>
            <div class="col-12 col-lg-3">
                <div class="top-text">
                    <h2>Quick Links</h2>
                </div>
                <div class="page-links">
                    <ul>
                        <li><a (click)="scrollToElement('#book')">Book</a></li>
                        <li *ngIf="!hasUser"><a href="/login">Login</a></li>
                        <li *ngIf="!hasUser"><a href="/registration">Sign Up</a></li>
                        <li><a routerLink="/blogs">Helpful Info</a></li>
                        <li><a (click)="scrollToElement('#features')">Features</a></li>
                        <li><a (click)="scrollToElement('#about-us')">About Us</a></li>
                        <li><a routerLink="/contact-us">Contact Us</a></li>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li (click)="openFile()"><a href="">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-12 col-lg-4">
                <div class="top-text">
                    <h2>Our Moto</h2>
                </div>
                <div class="moto-text">
                    <p>Elite Moving: Seamless Moves Across UK and Europe. Expert Packing, Secure Storage - Your Trusted
                        Partner for Stress-free Relocations.</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="copy-text text-center">
                    <p>&copy; Elite Moving 2023. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    </div>
</footer>
import { Injectable } from '@angular/core'
import { LoaderOptionsObject } from '../interfaces/loaderOptions'
@Injectable({
  providedIn: 'root'
})
export class ConstantsService {
  public static USER_ROLES = {
    ADMIN: 'Admin',
    Customer: 'Customer',
    TradePartner: 'TradePartner'
  }

  //------USER TYPE
  public static ADMIN = 'Admin'
  public static Customer = 'Customer'
  public static TardePartner = 'TradePartner'

  //------Test TYPE
  public static Random = 'Random'
  public static Comprehensive = 'Comprehensive'
  public static Predefined = 'Predefined'


  public static SKELETON_LOADER_DEFAULT_CONFIG: LoaderOptionsObject = {
    rows: 5,
    cols: 4,
    colSpans: {
      0: 1
    }
  }

  public static createLoaderConfig(rows: number, cols: number, colSpans: number) {
    const loaderObject: LoaderOptionsObject = {
      rows: rows,
      cols: cols,
      colSpans: {
        0: colSpans
      }
    }
    return loaderObject
  }

  public static EDITOR_CONFIG = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ font: [] }],
      [{ header: 1 }, { header: 2 }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ direction: 'rtl' }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
      ['link']
    ]
  }
  public LANGUAGES = {
    en: 'English',
    no: 'Norwegian'
  }

  public static descriptionList: any = [
    'Carryover Credit',
    'Payment to Account',
    'Prorated for Month',
    'Removed from Class & Credited',
    'Transfer'
  ]


  public MONTHS = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December'
  }

  public DAYS: any = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
  }

  public TIME = [
    '08:00 - 10:00 AM',
    '12:00 - 02:00 PM',
    '04:00 - 06:00 PM',
    // '12:00 PM',
    // '01:00 PM',
    // '02:00 PM',
    // '03:00 PM',
    // '04:00 PM',
    // '05:00 PM',
    // '06:00 PM',
    // '07:00 PM',
    // '08:00 PM',
    // '09:00 PM',
    // '10:00 PM',
    // '11:00 PM',
    // '12:00 AM',
    // '01:00 AM',
    // '02:00 AM',
    // '03:00 AM',
    // '04:00 AM',
    // '05:00 AM',
    // '06:00 AM',
    // '07:00 AM',
    // '08:00 AM',
  ]

  public ulezBoundries =
    [{
      "lng": -0.0173701532,
      "lat": 51.6011227905
    },
    {
      "lng": -0.0254261323,
      "lat": 51.6056060815
    },
    {
      "lng": -0.0290077758,
      "lat": 51.6073807432
    },
    {
      "lng": -0.0301055745,
      "lat": 51.6083574993
    },
    {
      "lng": -0.0303612738,
      "lat": 51.6090362139
    },
    {
      "lng": -0.0305369486,
      "lat": 51.6102459821
    },
    {
      "lng": -0.0318982751,
      "lat": 51.6117241445
    },
    {
      "lng": -0.0343066765,
      "lat": 51.6127228698
    },
    {
      "lng": -0.0370290387,
      "lat": 51.613052452
    },
    {
      "lng": -0.0390846403,
      "lat": 51.6129802969
    },
    {
      "lng": -0.0416158871,
      "lat": 51.6124546741
    },
    {
      "lng": -0.043926794,
      "lat": 51.6117478584
    },
    {
      "lng": -0.0459945021,
      "lat": 51.6113918784
    },
    {
      "lng": -0.0478684665,
      "lat": 51.6115650313
    },
    {
      "lng": -0.0510701942,
      "lat": 51.6126831499
    },
    {
      "lng": -0.0555899638,
      "lat": 51.6136455025
    },
    {
      "lng": -0.0648941883,
      "lat": 51.6147223282
    },
    {
      "lng": -0.0681371273,
      "lat": 51.6148823292
    },
    {
      "lng": -0.0708299261,
      "lat": 51.614571731
    },
    {
      "lng": -0.0763818197,
      "lat": 51.6140595202
    },
    {
      "lng": -0.0790472749,
      "lat": 51.6142841837
    },
    {
      "lng": -0.0816084461,
      "lat": 51.6145000726
    },
    {
      "lng": -0.0830642687,
      "lat": 51.6151272939
    },
    {
      "lng": -0.0847317458,
      "lat": 51.6161483901
    },
    {
      "lng": -0.087334714,
      "lat": 51.6166168228
    },
    {
      "lng": -0.0922976362,
      "lat": 51.6165557157
    },
    {
      "lng": -0.0954348807,
      "lat": 51.6162493592
    },
    {
      "lng": -0.0978554622,
      "lat": 51.6159005512
    },
    {
      "lng": -0.1021323788,
      "lat": 51.6145145603
    },
    {
      "lng": -0.1076903747,
      "lat": 51.6124747758
    },
    {
      "lng": -0.1097516569,
      "lat": 51.6122595307
    },
    {
      "lng": -0.1135570201,
      "lat": 51.6126047754
    },
    {
      "lng": -0.117273395,
      "lat": 51.6123449794
    },
    {
      "lng": -0.119264424,
      "lat": 51.612447937
    },
    {
      "lng": -0.1239066981,
      "lat": 51.6136530835
    },
    {
      "lng": -0.1262587885,
      "lat": 51.6142635722
    },
    {
      "lng": -0.1277708453,
      "lat": 51.614926514
    },
    {
      "lng": -0.1288318754,
      "lat": 51.6146130575
    },
    {
      "lng": -0.1323432565,
      "lat": 51.6132601003
    },
    {
      "lng": -0.1355639789,
      "lat": 51.6111817108
    },
    {
      "lng": -0.1402950578,
      "lat": 51.6098014945
    },
    {
      "lng": -0.1445598578,
      "lat": 51.6086621946
    },
    {
      "lng": -0.1526663162,
      "lat": 51.6060980598
    },
    {
      "lng": -0.1568804146,
      "lat": 51.6056618856
    },
    {
      "lng": -0.1597828037,
      "lat": 51.6057784568
    },
    {
      "lng": -0.1618877978,
      "lat": 51.605650082
    },
    {
      "lng": -0.1702275917,
      "lat": 51.6026054321
    },
    {
      "lng": -0.1745251638,
      "lat": 51.6006137213
    },
    {
      "lng": -0.1795016064,
      "lat": 51.5973191364
    },
    {
      "lng": -0.1905784351,
      "lat": 51.5910661122
    },
    {
      "lng": -0.1924887055,
      "lat": 51.5903146208
    },
    {
      "lng": -0.194097243,
      "lat": 51.5899844829
    },
    {
      "lng": -0.1961599344,
      "lat": 51.5896968226
    },
    {
      "lng": -0.2002001734,
      "lat": 51.5898298441
    },
    {
      "lng": -0.2017975273,
      "lat": 51.5897834227
    },
    {
      "lng": -0.2032920158,
      "lat": 51.5894514119
    },
    {
      "lng": -0.2063993205,
      "lat": 51.5886826131
    },
    {
      "lng": -0.2078550546,
      "lat": 51.5878884985
    },
    {
      "lng": -0.2091497082,
      "lat": 51.5868434238
    },
    {
      "lng": -0.2101179375,
      "lat": 51.5854029189
    },
    {
      "lng": -0.2113443107,
      "lat": 51.5827012807
    },
    {
      "lng": -0.2114046391,
      "lat": 51.5816601781
    },
    {
      "lng": -0.2127642416,
      "lat": 51.5804030814
    },
    {
      "lng": -0.2143071927,
      "lat": 51.5788293506
    },
    {
      "lng": -0.2165789522,
      "lat": 51.5775506799
    },
    {
      "lng": -0.2184965552,
      "lat": 51.5765859963
    },
    {
      "lng": -0.2199044473,
      "lat": 51.5755425128
    },
    {
      "lng": -0.2216108184,
      "lat": 51.5741486097
    },
    {
      "lng": -0.2234671852,
      "lat": 51.5732893412
    },
    {
      "lng": -0.2298434208,
      "lat": 51.5719154473
    },
    {
      "lng": -0.2330783748,
      "lat": 51.5707368601
    },
    {
      "lng": -0.2370390253,
      "lat": 51.5699445121
    },
    {
      "lng": -0.2403178552,
      "lat": 51.5691063815
    },
    {
      "lng": -0.2432147039,
      "lat": 51.5678363596
    },
    {
      "lng": -0.2475439798,
      "lat": 51.5663392354
    },
    {
      "lng": -0.2500297474,
      "lat": 51.5653824263
    },
    {
      "lng": -0.2514476624,
      "lat": 51.564054764
    },
    {
      "lng": -0.2533047866,
      "lat": 51.5601779509
    },
    {
      "lng": -0.2534799682,
      "lat": 51.5592265397
    },
    {
      "lng": -0.2535762759,
      "lat": 51.5575553964
    },
    {
      "lng": -0.252737783,
      "lat": 51.5556616966
    },
    {
      "lng": -0.253542808,
      "lat": 51.5539699852
    },
    {
      "lng": -0.2543735733,
      "lat": 51.5530949374
    },
    {
      "lng": -0.2551838042,
      "lat": 51.5527520552
    },
    {
      "lng": -0.2599961456,
      "lat": 51.5519715782
    },
    {
      "lng": -0.2612060873,
      "lat": 51.5515990689
    },
    {
      "lng": -0.263724041,
      "lat": 51.5483122123
    },
    {
      "lng": -0.2725140513,
      "lat": 51.5446671876
    },
    {
      "lng": -0.2742122865,
      "lat": 51.5434143383
    },
    {
      "lng": -0.2770530284,
      "lat": 51.5405454928
    },
    {
      "lng": -0.2779829789,
      "lat": 51.5400247777
    },
    {
      "lng": -0.2800264655,
      "lat": 51.538672449
    },
    {
      "lng": -0.288307684,
      "lat": 51.5348890862
    },
    {
      "lng": -0.289356821,
      "lat": 51.5342300453
    },
    {
      "lng": -0.2922818452,
      "lat": 51.532107414
    },
    {
      "lng": -0.293127234,
      "lat": 51.5308064638
    },
    {
      "lng": -0.2924912836,
      "lat": 51.5295549021
    },
    {
      "lng": -0.2923449762,
      "lat": 51.5258614312
    },
    {
      "lng": -0.2924377319,
      "lat": 51.5249043667
    },
    {
      "lng": -0.2915875588,
      "lat": 51.5232947608
    },
    {
      "lng": -0.2915177142,
      "lat": 51.522122447
    },
    {
      "lng": -0.2913650742,
      "lat": 51.5170800246
    },
    {
      "lng": -0.2919710111,
      "lat": 51.5145687872
    },
    {
      "lng": -0.2921301127,
      "lat": 51.5118379809
    },
    {
      "lng": -0.2917179509,
      "lat": 51.5091698786
    },
    {
      "lng": -0.2914326543,
      "lat": 51.5076750119
    },
    {
      "lng": -0.2897886959,
      "lat": 51.5044919912
    },
    {
      "lng": -0.2885939654,
      "lat": 51.5029838561
    },
    {
      "lng": -0.2849738645,
      "lat": 51.5009433159
    },
    {
      "lng": -0.2835413567,
      "lat": 51.4997155543
    },
    {
      "lng": -0.2820540181,
      "lat": 51.496925232
    },
    {
      "lng": -0.2823694168,
      "lat": 51.494587149
    },
    {
      "lng": -0.2820662132,
      "lat": 51.4920626347
    },
    {
      "lng": -0.2876361404,
      "lat": 51.4890915114
    },
    {
      "lng": -0.2875274924,
      "lat": 51.4844046143
    },
    {
      "lng": -0.2868538911,
      "lat": 51.4826554408
    },
    {
      "lng": -0.2857481983,
      "lat": 51.4818228875
    },
    {
      "lng": -0.2837901936,
      "lat": 51.4809778555
    },
    {
      "lng": -0.2825248318,
      "lat": 51.4798590228
    },
    {
      "lng": -0.2817059374,
      "lat": 51.4789596041
    },
    {
      "lng": -0.2813984219,
      "lat": 51.478067773
    },
    {
      "lng": -0.2815487748,
      "lat": 51.4768655728
    },
    {
      "lng": -0.2816421139,
      "lat": 51.4761190543
    },
    {
      "lng": -0.2808206301,
      "lat": 51.4752906281
    },
    {
      "lng": -0.2762940491,
      "lat": 51.4732364936
    },
    {
      "lng": -0.2769629596,
      "lat": 51.4690579318
    },
    {
      "lng": -0.2771312548,
      "lat": 51.4676051081
    },
    {
      "lng": -0.2765165529,
      "lat": 51.4658213711
    },
    {
      "lng": -0.2766861805,
      "lat": 51.4643331464
    },
    {
      "lng": -0.2703266792,
      "lat": 51.4642040638
    },
    {
      "lng": -0.2692449035,
      "lat": 51.464259044
    },
    {
      "lng": -0.266954372,
      "lat": 51.4647222051
    },
    {
      "lng": -0.2651316205,
      "lat": 51.4648372246
    },
    {
      "lng": -0.2609721895,
      "lat": 51.4651306763
    },
    {
      "lng": -0.2530684699,
      "lat": 51.4652616533
    },
    {
      "lng": -0.2487589444,
      "lat": 51.4650199316
    },
    {
      "lng": -0.2445439506,
      "lat": 51.4652764402
    },
    {
      "lng": -0.2405253114,
      "lat": 51.4648612091
    },
    {
      "lng": -0.2352585602,
      "lat": 51.4643916457
    },
    {
      "lng": -0.2321611316,
      "lat": 51.4636706527
    },
    {
      "lng": -0.2271868503,
      "lat": 51.4629921585
    },
    {
      "lng": -0.2235106485,
      "lat": 51.4625460951
    },
    {
      "lng": -0.2207029234,
      "lat": 51.4616871406
    },
    {
      "lng": -0.2168759089,
      "lat": 51.4607416029
    },
    {
      "lng": -0.2146764632,
      "lat": 51.4603177022
    },
    {
      "lng": -0.2118619612,
      "lat": 51.4596359043
    },
    {
      "lng": -0.2082153062,
      "lat": 51.4584444428
    },
    {
      "lng": -0.2067654123,
      "lat": 51.4577478448
    },
    {
      "lng": -0.2055114845,
      "lat": 51.4569278241
    },
    {
      "lng": -0.2037989632,
      "lat": 51.4566021502
    },
    {
      "lng": -0.2006122571,
      "lat": 51.4567307774
    },
    {
      "lng": -0.1986178105,
      "lat": 51.4568776351
    },
    {
      "lng": -0.1963202339,
      "lat": 51.4573797669
    },
    {
      "lng": -0.1942358459,
      "lat": 51.4570587562
    },
    {
      "lng": -0.1926045594,
      "lat": 51.456643191
    },
    {
      "lng": -0.191014573,
      "lat": 51.4566186838
    },
    {
      "lng": -0.1874715987,
      "lat": 51.4571319085
    },
    {
      "lng": -0.1846071659,
      "lat": 51.4577265506
    },
    {
      "lng": -0.1803329502,
      "lat": 51.458050774
    },
    {
      "lng": -0.175489339,
      "lat": 51.458401409
    },
    {
      "lng": -0.1696227312,
      "lat": 51.4605233712
    },
    {
      "lng": -0.1670996516,
      "lat": 51.4607552803
    },
    {
      "lng": -0.1615756782,
      "lat": 51.4610592418
    },
    {
      "lng": -0.1567942136,
      "lat": 51.4612681751
    },
    {
      "lng": -0.1565235235,
      "lat": 51.4595246079
    },
    {
      "lng": -0.1541294236,
      "lat": 51.4568958083
    },
    {
      "lng": -0.1508934587,
      "lat": 51.4554250093
    },
    {
      "lng": -0.1476562645,
      "lat": 51.4539896138
    },
    {
      "lng": -0.1460708526,
      "lat": 51.4538581147
    },
    {
      "lng": -0.144328081,
      "lat": 51.4534045941
    },
    {
      "lng": -0.1423811472,
      "lat": 51.4523799003
    },
    {
      "lng": -0.1421534784,
      "lat": 51.4509919885
    },
    {
      "lng": -0.1376636563,
      "lat": 51.4496428607
    },
    {
      "lng": -0.131776648,
      "lat": 51.4477388808
    },
    {
      "lng": -0.1296965146,
      "lat": 51.4472088063
    },
    {
      "lng": -0.1244832964,
      "lat": 51.445193658
    },
    {
      "lng": -0.1225846084,
      "lat": 51.4447167985
    },
    {
      "lng": -0.1217520548,
      "lat": 51.444242076
    },
    {
      "lng": -0.1165781067,
      "lat": 51.4429875991
    },
    {
      "lng": -0.1151315019,
      "lat": 51.4422544674
    },
    {
      "lng": -0.1134711663,
      "lat": 51.4411983617
    },
    {
      "lng": -0.1098038136,
      "lat": 51.4406068542
    },
    {
      "lng": -0.1062950736,
      "lat": 51.4403017082
    },
    {
      "lng": -0.105757901,
      "lat": 51.4409319655
    },
    {
      "lng": -0.1037845007,
      "lat": 51.4405806219
    },
    {
      "lng": -0.102055941,
      "lat": 51.4398071603
    },
    {
      "lng": -0.0998077465,
      "lat": 51.4392383179
    },
    {
      "lng": -0.0984412298,
      "lat": 51.4393225938
    },
    {
      "lng": -0.096994328,
      "lat": 51.439973555
    },
    {
      "lng": -0.094687149,
      "lat": 51.4408234945
    },
    {
      "lng": -0.0928037894,
      "lat": 51.4410412559
    },
    {
      "lng": -0.0905260657,
      "lat": 51.4411817187
    },
    {
      "lng": -0.0882078352,
      "lat": 51.4409309371
    },
    {
      "lng": -0.0851132676,
      "lat": 51.4415902781
    },
    {
      "lng": -0.0835660134,
      "lat": 51.4419199168
    },
    {
      "lng": -0.0814016965,
      "lat": 51.4420619626
    },
    {
      "lng": -0.0757792891,
      "lat": 51.4428153858
    },
    {
      "lng": -0.0732762751,
      "lat": 51.4434905094
    },
    {
      "lng": -0.070168028,
      "lat": 51.4431198314
    },
    {
      "lng": -0.0676063187,
      "lat": 51.4432550685
    },
    {
      "lng": -0.0662382837,
      "lat": 51.4420256295
    },
    {
      "lng": -0.0646895763,
      "lat": 51.4410416829
    },
    {
      "lng": -0.0628155175,
      "lat": 51.4407417134
    },
    {
      "lng": -0.058743671,
      "lat": 51.4406238669
    },
    {
      "lng": -0.0573412194,
      "lat": 51.4402101664
    },
    {
      "lng": -0.0547666632,
      "lat": 51.4393155217
    },
    {
      "lng": -0.0540825552,
      "lat": 51.4393751844
    },
    {
      "lng": -0.0542212648,
      "lat": 51.440122878
    },
    {
      "lng": -0.0533565837,
      "lat": 51.440182324
    },
    {
      "lng": -0.0518851983,
      "lat": 51.4402970371
    },
    {
      "lng": -0.0511012048,
      "lat": 51.4400355047
    },
    {
      "lng": -0.0500629982,
      "lat": 51.4404086801
    },
    {
      "lng": -0.0474696219,
      "lat": 51.4412883447
    },
    {
      "lng": -0.0473689658,
      "lat": 51.4423160601
    },
    {
      "lng": -0.0428790541,
      "lat": 51.4423831873
    },
    {
      "lng": -0.0400518037,
      "lat": 51.4420874426
    },
    {
      "lng": -0.0366296706,
      "lat": 51.4424206061
    },
    {
      "lng": -0.0345863925,
      "lat": 51.4423863541
    },
    {
      "lng": -0.0326119827,
      "lat": 51.4420692192
    },
    {
      "lng": -0.0315275807,
      "lat": 51.4421930551
    },
    {
      "lng": -0.0293279995,
      "lat": 51.4431499609
    },
    {
      "lng": -0.0280670828,
      "lat": 51.4434126707
    },
    {
      "lng": -0.0271635889,
      "lat": 51.4432910333
    },
    {
      "lng": -0.0256526642,
      "lat": 51.4440820166
    },
    {
      "lng": -0.0243272551,
      "lat": 51.4445210808
    },
    {
      "lng": -0.0226107447,
      "lat": 51.44481167
    },
    {
      "lng": -0.0209570228,
      "lat": 51.4449612472
    },
    {
      "lng": -0.0202221317,
      "lat": 51.4448778228
    },
    {
      "lng": -0.019617735,
      "lat": 51.4457195383
    },
    {
      "lng": -0.0190960812,
      "lat": 51.445959221
    },
    {
      "lng": -0.0182401501,
      "lat": 51.4460512076
    },
    {
      "lng": -0.0168854707,
      "lat": 51.4458508436
    },
    {
      "lng": -0.0143451084,
      "lat": 51.445488442
    },
    {
      "lng": -0.0115760738,
      "lat": 51.4451575299
    },
    {
      "lng": -0.0064707277,
      "lat": 51.4449999381
    },
    {
      "lng": -0.0010738383,
      "lat": 51.4450145334
    },
    {
      "lng": 0.0043859302,
      "lat": 51.4451698526
    },
    {
      "lng": 0.0061041879,
      "lat": 51.4454954144
    },
    {
      "lng": 0.0125826751,
      "lat": 51.4458769209
    },
    {
      "lng": 0.0148291092,
      "lat": 51.44626872
    },
    {
      "lng": 0.0177583183,
      "lat": 51.4469993537
    },
    {
      "lng": 0.0216556724,
      "lat": 51.4477840727
    },
    {
      "lng": 0.0275823686,
      "lat": 51.4494920558
    },
    {
      "lng": 0.0325381465,
      "lat": 51.4510745866
    },
    {
      "lng": 0.0362501018,
      "lat": 51.4527849452
    },
    {
      "lng": 0.0422889095,
      "lat": 51.4569749088
    },
    {
      "lng": 0.0444466345,
      "lat": 51.4582150804
    },
    {
      "lng": 0.0472809377,
      "lat": 51.4593370282
    },
    {
      "lng": 0.0485808501,
      "lat": 51.4604501407
    },
    {
      "lng": 0.0491692559,
      "lat": 51.4609013233
    },
    {
      "lng": 0.0496666363,
      "lat": 51.4618347149
    },
    {
      "lng": 0.0515967502,
      "lat": 51.4656152409
    },
    {
      "lng": 0.0526433554,
      "lat": 51.4674071932
    },
    {
      "lng": 0.0542026821,
      "lat": 51.4692256621
    },
    {
      "lng": 0.0547365876,
      "lat": 51.4709910702
    },
    {
      "lng": 0.0552329629,
      "lat": 51.4731830921
    },
    {
      "lng": 0.056033563,
      "lat": 51.4745533687
    },
    {
      "lng": 0.0566828706,
      "lat": 51.4763522534
    },
    {
      "lng": 0.0576507635,
      "lat": 51.4776486504
    },
    {
      "lng": 0.0588978544,
      "lat": 51.4788336008
    },
    {
      "lng": 0.0600866786,
      "lat": 51.4799840534
    },
    {
      "lng": 0.0619834802,
      "lat": 51.482967836
    },
    {
      "lng": 0.0617474097,
      "lat": 51.4840368838
    },
    {
      "lng": 0.0623747847,
      "lat": 51.485339146
    },
    {
      "lng": 0.0633069929,
      "lat": 51.4870975107
    },
    {
      "lng": 0.0608724742,
      "lat": 51.492862361
    },
    {
      "lng": 0.0609944071,
      "lat": 51.4938113906
    },
    {
      "lng": 0.0608655812,
      "lat": 51.4947365082
    },
    {
      "lng": 0.0615682727,
      "lat": 51.4951856272
    },
    {
      "lng": 0.0625341839,
      "lat": 51.4951686406
    },
    {
      "lng": 0.0616050693,
      "lat": 51.4985215464
    },
    {
      "lng": 0.0627218675,
      "lat": 51.4987585779
    },
    {
      "lng": 0.063502726,
      "lat": 51.4989851497
    },
    {
      "lng": 0.063492347,
      "lat": 51.5000147199
    },
    {
      "lng": 0.0653124814,
      "lat": 51.5000180969
    },
    {
      "lng": 0.0674294032,
      "lat": 51.5003003093
    },
    {
      "lng": 0.0692184026,
      "lat": 51.5008721887
    },
    {
      "lng": 0.0705414244,
      "lat": 51.5012037758
    },
    {
      "lng": 0.0707743188,
      "lat": 51.5017785558
    },
    {
      "lng": 0.0703200701,
      "lat": 51.5025920271
    },
    {
      "lng": 0.0712963935,
      "lat": 51.5053079593
    },
    {
      "lng": 0.0712390979,
      "lat": 51.5065514443
    },
    {
      "lng": 0.0711135457,
      "lat": 51.5075475504
    },
    {
      "lng": 0.07107882,
      "lat": 51.5084924247
    },
    {
      "lng": 0.0709308053,
      "lat": 51.5097870428
    },
    {
      "lng": 0.0710144842,
      "lat": 51.5116313711
    },
    {
      "lng": 0.0706187135,
      "lat": 51.5141940406
    },
    {
      "lng": 0.071112113,
      "lat": 51.5150372541
    },
    {
      "lng": 0.0718522442,
      "lat": 51.5163019797
    },
    {
      "lng": 0.0732038313,
      "lat": 51.5197567163
    },
    {
      "lng": 0.0727625471,
      "lat": 51.5225686659
    },
    {
      "lng": 0.0734566883,
      "lat": 51.5253250397
    },
    {
      "lng": 0.0727057915,
      "lat": 51.5263321814
    },
    {
      "lng": 0.0717188707,
      "lat": 51.5321708877
    },
    {
      "lng": 0.068899446,
      "lat": 51.5365156326
    },
    {
      "lng": 0.0668979914,
      "lat": 51.5388226472
    },
    {
      "lng": 0.0663999453,
      "lat": 51.5403932018
    },
    {
      "lng": 0.0666852902,
      "lat": 51.5416659578
    },
    {
      "lng": 0.0677486158,
      "lat": 51.5437770088
    },
    {
      "lng": 0.0683602873,
      "lat": 51.545966943
    },
    {
      "lng": 0.0682115296,
      "lat": 51.5477088109
    },
    {
      "lng": 0.0680074507,
      "lat": 51.5494871639
    },
    {
      "lng": 0.0672924817,
      "lat": 51.5525523856
    },
    {
      "lng": 0.0661549371,
      "lat": 51.5550925491
    },
    {
      "lng": 0.0640242416,
      "lat": 51.5570822637
    },
    {
      "lng": 0.0604269021,
      "lat": 51.5593817136
    },
    {
      "lng": 0.0584442778,
      "lat": 51.5608718486
    },
    {
      "lng": 0.056200842,
      "lat": 51.562899015
    },
    {
      "lng": 0.0545703943,
      "lat": 51.5646312982
    },
    {
      "lng": 0.0523586528,
      "lat": 51.5673676688
    },
    {
      "lng": 0.0506283701,
      "lat": 51.5694211416
    },
    {
      "lng": 0.0487715203,
      "lat": 51.5711928018
    },
    {
      "lng": 0.0460128407,
      "lat": 51.5737422788
    },
    {
      "lng": 0.0435872787,
      "lat": 51.5762525132
    },
    {
      "lng": 0.0413491261,
      "lat": 51.5796989539
    },
    {
      "lng": 0.0398780196,
      "lat": 51.5827513818
    },
    {
      "lng": 0.0395805087,
      "lat": 51.5847344646
    },
    {
      "lng": 0.0393975677,
      "lat": 51.5870093525
    },
    {
      "lng": 0.0383730997,
      "lat": 51.5895827621
    },
    {
      "lng": 0.03715403,
      "lat": 51.5916271446
    },
    {
      "lng": 0.0353115165,
      "lat": 51.5937533446
    },
    {
      "lng": 0.0334830348,
      "lat": 51.594920839
    },
    {
      "lng": 0.0313997567,
      "lat": 51.595489388
    },
    {
      "lng": 0.0289162188,
      "lat": 51.5960292176
    },
    {
      "lng": 0.0255136302,
      "lat": 51.59640739
    },
    {
      "lng": 0.0218927836,
      "lat": 51.5957244397
    },
    {
      "lng": 0.0199330706,
      "lat": 51.5952257982
    },
    {
      "lng": 0.0141214526,
      "lat": 51.595254699
    },
    {
      "lng": 0.0119010895,
      "lat": 51.5956841273
    },
    {
      "lng": 0.0090416854,
      "lat": 51.5963711342
    },
    {
      "lng": 0.0048346572,
      "lat": 51.5978983833
    },
    {
      "lng": 0.0022570131,
      "lat": 51.5989007398
    },
    {
      "lng": -0.0004439305,
      "lat": 51.5996921738
    },
    {
      "lng": -0.0028757083,
      "lat": 51.6001240461
    },
    {
      "lng": -0.0070823047,
      "lat": 51.6003730825
    },
    {
      "lng": -0.0106166079,
      "lat": 51.6003620375
    },
    {
      "lng": -0.0149420972,
      "lat": 51.6005063065
    },
    {
      "lng": -0.0173701532,
      "lat": 51.6011227905
    },
    ];
  public PAYMENT_METHOD = ['Cash', 'Card']
  public DATE_TIME_FORMAT = {
    PRINT_DATE: 'MMMM Do YYYY, h:mm A',
    SYN_DATE: 'DD/MM/YYYY',
    CHAR_DATE: 'MMM DD, YYYY',
    INVOICE_MONTH: 'MMM YYYY',
    INVOICE_DATE: 'MMM DD, YYYY HH:mm',
    SHORT_DATE: 'DD-MM-YYYY',
    SHORT_DATE_INPUT: 'YYYY-MM-DD',
    DATE: 'dddd, MMMM DD, YYYY',
    TIME: 'HH:mm',
    DATE_TIME: 'dddd, MMMM DD, YYYY hh:mm A',
    AM_PM: 'HH:mm A',
    DOC_DATE: 'DD MMM, YYYY',
    DATE_AMPM: 'DD MMM, YYYY, h a'
  }

  public MONTHSDATA = [
    {
      name: 'January',
      short: 'Jan',
      number: 1,
      days: 31
    },
    {
      name: 'February',
      short: 'Feb',
      number: 2,
      days: 28
    },
    {
      name: 'March',
      short: 'Mar',
      number: 3,
      days: 31
    },
    {
      name: 'April',
      short: 'Apr',
      number: 4,
      days: 30
    },
    {
      name: 'May',
      short: 'May',
      number: 5,
      days: 31
    },
    {
      name: 'June',
      short: 'Jun',
      number: 6,
      days: 30
    },
    {
      name: 'July',
      short: 'Jul',
      number: 7,
      days: 31
    },
    {
      name: 'August',
      short: 'Aug',
      number: 8,
      days: 31
    },
    {
      name: 'September',
      short: 'Sep',
      number: 9,
      days: 30
    },
    {
      name: 'October',
      short: 'Oct',
      number: 10,
      days: 31
    },
    {
      name: 'November',
      short: 'Nov',
      number: 11,
      days: 30
    },
    {
      name: 'December',
      short: 'Dec',
      number: 12,
      days: 31
    }
  ]

  public SUPER_SEEDER: any = [
    'studentFirstName',
    'studentLastName',
    'studentPhoneNumber',
    'studentBalance',
    'studentEmail',
    // 'contactFirstName',
    // 'contactLastName',
    // 'contactEmail',
    // 'contactPhoneNumber',
    // 'contactRelation',
    // 'lastFiveTransactions',
    // 'lastTenTransactions',
    // 'lastFifteenTransactions',
    // 'onlinePaymentLink'
  ]

  public SMSSUPER_SEEDER: any = [
    'studentFirstName',
    'studentMiddleName',
    'studentLastName',
    'studentPhoneNumber',
    'studentBalance',
    'studentEmail',
    'contactFirstName',
    'contactLastName',
    'contactEmail',
    'contactPhoneNumber',
    'contactRelation',
    'onlinePaymentLink'
  ]
}
export { }
